/* ProjectsList.css */

/* WebKit-based browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Color of the scrollbar thumb and track */
}